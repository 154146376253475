import React, { useEffect, useMemo } from "react";

import { useRouter } from "next/router";

import { withConfig } from "../utils/withConfig";
import { CircularProgressIndicator } from "../components/common/CircularProgressIndicator/CircularProgressIndicator";
import { EMPTY_NAVBAR } from "../constants/headerTypes";
import { COUNTRY_ROUTE } from "../components/pagesComponents/Cities/constants/ownConstants";

export const getStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await withConfig(locale)),
    },
  };
};

const NotFound = () => {
  const router = useRouter();

  const url = useMemo(() => {
    const { asPath } = router;
    const hasClubStart = asPath.indexOf("/club");

    if (hasClubStart === -1) return COUNTRY_ROUTE;

    if (/\/club\/.+\/.+/.test(asPath)) {
      const [club] = asPath.substring(hasClubStart + 6).split("/");

      const clubRoute = `/club/${club}`;
      return clubRoute;
    }

    return COUNTRY_ROUTE;
  }, [router]);

  useEffect(() => {
    router.replace(url);
  }, [url]);

  return <CircularProgressIndicator fullscreen isPortal />;
};

NotFound.navbar = EMPTY_NAVBAR;
NotFound.foobar = EMPTY_NAVBAR;

export default NotFound;
